import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { AlertSuccess, AlertDanger } from '../../../../modules/alerts/Alerts'
import { getAdminENV, updateAdminENV } from '../../api'
import { Form } from 'react-bootstrap'
import { useAppContext } from '../../../AppContext/AppContext'

const profileDetailsSchema = Yup.object().shape({
  CHAT_MODEL: Yup.string().required('CHAT MODEL is required'),
  conversationNumberToPass: Yup.string().required('Conversation Number To Pass is required'),
  NO_OF_CITATIONS: Yup.string().required('NO OF CONTEXT TO RETRIVE is required'),
  OPENAI_FAILURE_RESPONSE: Yup.string().required('OPENAI FAILURE RESPONSE is required'),
  DEFAULT_CHAT_NAME: Yup.string().required('DEFAULT CHAT NAME is required'),
  PPT_PROMPT: Yup.string().required('PPT prompt is required'),
  PDF_PROMPT: Yup.string().required('PDF prompt is required'),
  DOC_PROMPT: Yup.string().required('DOC prompt is required'),
  XLSX_PROMPT: Yup.string().required('XLSX prompt is required'),
  IMAGE_PROMPT: Yup.string().required('IMAGE prompt is required'),
  AUDIO_PROMPT: Yup.string().required('AUDIO prompt is required'),
  VIDEO_PROMPT: Yup.string().required('VIDEO prompt is required'),
  Embedding_Model: Yup.string().required('Embedding Model is required'),
  CHAT_OUTPUT_TOKEN: Yup.number().min(1, 'CHAT OUTPUT TOKEN must be at least 1').max(4096, 'CHAT OUTPUT TOKEN must be at most 4096').required('Token is required'),
});

export function Settings() {
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [envData, setENVData] = useState<any>([])
  const {appData} = useAppContext()
  const [modelIndex, setModelIndex] = useState<any>(0)
  const [model, setModel] = useState<any>()

  if (successMessage !== "") {
    setTimeout(() => {
      setChecked(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, 200);
    }, 5000);
  }

  if (errorMessage !== "") {
    setTimeout(() => {
      setChecked(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 200);
    }, 5000);
  }

  const initialValues: any = {};
  const formik = useFormik<any>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        updateAdminENV(values)
          .then((response) => {
            if (response.data.success) {
              Object.entries<string>(values).forEach(([key, value]) => {
                formik.setFieldValue(key, value);
              });
              setChecked(true)
              setSuccessMessage(response.data.message)
              setLoading(false)
              window.scrollTo(0, 0)
            } else {
              setChecked(true)
              setErrorMessage(response.data.message)
              setLoading(false)
              window.scrollTo(0, 0)
            }
          })
          .catch((err) => {
            setChecked(true)
            setErrorMessage('Failed to update details')
            setLoading(false)
            window.scrollTo(0, 0)
          })
      }, 1000)
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: any = await getAdminENV();
        setENVData(data.data.env);

        const initialFormValues: any = {};
        data.data.env.forEach((env: any) => {
          initialFormValues[env.meta_key] = env.meta_value;
        });
        formik.setValues(initialFormValues);
        setModel(initialFormValues["Embedding_Model"]);
        const index = appData.embeddingData.findIndex((item:any) => Object.keys(item)[0] === (initialFormValues["Embedding_Model"]).trim());
        setModelIndex(index)
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    fetchData();
  }, []);

  const transformString = (inputString: string): string => {
    if (/([a-z0-9])([A-Z])/.test(inputString)) {
      return inputString.replace(/([a-z])([0-9]|[A-Z])/g, '$1 $2').toUpperCase();
    } else if (/_/.test(inputString)) {
      return inputString.replace(/_/g, ' ').toUpperCase();
    } else {
      return inputString.toUpperCase();
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Configuration</h3>
        </div>
      </div>

      {successMessage !== "" ? (
        <AlertSuccess message={successMessage} checked={checked} />
      ) : null}

      {errorMessage !== "" ? (
        <AlertDanger message={errorMessage} checked={checked} />
      ) : null}

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {envData.length > 0 && (
              envData.map((env: any) => (
                <div key={env.id} className='row mb-6'>
                  {
                    env.meta_key === 'FORMAT_SUFFIX' ?
                    <>
                    {
                      formik.values.FORMAT_CHAT_RESPONSE == 1 &&
                      <label className='col-lg-3 col-form-label fw-bold fs-6'>
                        <span className='required'>{transformString(env.meta_key)}</span>
                      </label>
                    }
                    </>
                    :
                    <>
                    {
                      (env.meta_key === 'IMAGE_PROMPT' || env.meta_key === 'AUDIO_PROMPT' || env.meta_key === 'VIDEO_PROMPT') ?
                        <label className='col-lg-3 col-form-label fw-bold fs-6'>
                          <span className='required'>{transformString(env.meta_key)}</span>
                           <i className="ms-3 bi bi-info-circle fs-6"
                          title={`Changes may impact the Summary generated for ${env.meta_key === 'IMAGE_PROMPT' ? "Image file" : ""}${env.meta_key === 'AUDIO_PROMPT' ? "Audio file" : ""}${env.meta_key === 'VIDEO_PROMPT' ? "Video file" : ""}
                          `}>
                          </i>
                        </label>
                        :
                        <>
                        {
                           (env.meta_key === 'PDF_PROMPT' || env.meta_key === 'PPT_PROMPT' || env.meta_key === 'DOC_PROMPT' || env.meta_key === 'XLSX_PROMPT') ?
                           <label className='col-lg-3 col-form-label fw-bold fs-6'>
                             <span className='required'>{transformString(env.meta_key)}</span>
                              <i className="ms-3 bi bi-info-circle fs-6"
                             title={`Changes may impact the Summary generated for ${env.meta_key === 'PDF_PROMPT' ? "PDF file" : ""}${env.meta_key === 'PPT_PROMPT' ? "PPT file" : ""}${env.meta_key === 'DOC_PROMPT' ? "DOC file" : ""}${env.meta_key === 'XLSX_PROMPT' ? "XLSX file" : ""}
                             `}>
                             </i>
                           </label>
                           :
                           <>
                            {
                              env.meta_key === "FORMAT_CHAT_RESPONSE" ?
                              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>{transformString(env.meta_key)}</span>
                                <i className="ms-3 bi bi-info-circle fs-6"
                                title={`${appData?.defaultResponseSuffix}`}>
                                </i>
                              </label>
                            :
                            <>
                            {
                              env.meta_key === 'FILE_UPLOAD_EXPIRY' ? (
                              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>
                                  {transformString(env.meta_key)} (Hours)
                                </span>
                                
                              </label>
                              ) 
                              :
                              env.meta_key === 'USER_RECORDING_PROMPT'?(
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>
                                  {transformString(env.meta_key)} (Minutes)
                                </span>                                
                              </label>
                              ):
                              env.meta_key === 'RECORDING_MONTHLY_LIMIT'?(
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>
                                  {transformString(env.meta_key)}
                                </span>                                
                              </label>
                              ):
                              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>{transformString(env.meta_key)}</span>
                              </label>
                            }
                            </>


                            }
                           
                           </>
                        }
                        </>
                    }
                    </>
                  }
                 
                  <div className='col-lg-9 fv-row'>
                    {env.meta_key === 'CHAT_MODEL' ? (
                      <select
                        className='form-control form-control-lg form-control-solid'
                        {...formik.getFieldProps(env.meta_key)}
                        style={{ appearance: 'auto' }}
                      >
                        {
                          appData?.chatModel.split(",").map((item, i) => {
                            return <>
                              <option value={`${item}`} key={item}>{item}</option>
                            </>
                          })
                        }
                      </select>
                    ) : env.meta_key === "CHAT_OUTPUT_TOKEN" ? (
                      <select
                        className='form-control form-control-lg form-control-solid'
                        {...formik.getFieldProps(env.meta_key)}
                        style={{ appearance: 'auto' }}
                      >
                        <option value='512'>512</option>
                        <option value='1024'>1024</option>
                        <option value='2048'>2048</option>
                        <option value='4096'>4096</option>
                      </select>
                    ) : env.meta_key === 'NO_OF_CITATIONS' ? (
                      <select
                        className='form-control form-control-lg form-control-solid'
                        {...formik.getFieldProps(env.meta_key)}
                        style={{ appearance: 'auto' }}
                      >
                        <option value='0'>0</option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                      </select>
                    ) : env.meta_key === 'FILE_UPLOAD_EXPIRY' ? (
                      <select
                        className='form-control form-control-lg form-control-solid'
                        {...formik.getFieldProps(env.meta_key)}
                        style={{appearance: 'auto'}}
                      >
                        <option value='12'>12 </option>
                        <option value='24'>24 </option>
                        <option value='32'>32 </option>
                        <option value='48'>48 </option>
                        <option value='72'>72 </option>
                      </select>
                      ): env.meta_key === 'USER_RECORDING_PROMPT' ? (
                        <select
                          className='form-control form-control-lg form-control-solid'
                          {...formik.getFieldProps(env.meta_key)}
                          style={{appearance: 'auto'}}
                        >
                          <option value='10'>10 </option>
                          <option value='30'>30 </option>
                          <option value='60'>60 </option>
                          <option value='120'>120 </option>
                          <option value='150'>150 </option>
                        </select>
                        ): env.meta_key === 'RECORDING_MONTHLY_LIMIT' ? (
                          <select
                            className='form-control form-control-lg form-control-solid'
                            {...formik.getFieldProps(env.meta_key)}
                            style={{appearance: 'auto'}}
                          >
                            <option value='10'>10 </option>
                            <option value='20'>20 </option>
                            <option value='30'>30 </option>
                            <option value='40'>40 </option>
                            <option value='50'>50 </option>
                          </select>
                    ) : env.meta_key === 'conversationNumberToPass' ? (
                      <select
                        className='form-control form-control-lg form-control-solid'
                        {...formik.getFieldProps(env.meta_key)}
                        style={{ appearance: 'auto' }}
                      >
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                        <option value='11'>11</option>
                        <option value='12'>12</option>
                        <option value='13'>13</option>
                        <option value='14'>14</option>
                        <option value='15'>15</option>
                        <option value='16'>16</option>
                        <option value='17'>17</option>
                        <option value='18'>18</option>
                        <option value='19'>19</option>
                        <option value='20'>20</option>
                      </select>
                    ) : env.meta_key === 'Embedding_Model' ? (
                      <select
                        className='form-control form-control-lg form-control-solid'
                        {...formik.getFieldProps(env.meta_key)}
                        style={{ appearance: 'auto' }} 
                        onChange={(e)=>{
                          e.preventDefault()
                          setModelIndex(e.target.selectedIndex)
                          setModel(e.target.value)
                          formik.setFieldValue("Embedding_Model", e.target.value);
                        }}
                        value={model}
                      >
                        {
                          appData?.embeddingData.map((item: any, ind: number) => {
                            return <>
                              <option value={`${Object.keys(item)}`} >{Object.keys(item)}</option>
                            </>
                          })
                        }
                      </select>
                    ) : env.meta_key === 'FORMAT_CHAT_RESPONSE' ? (
                      <>
                      <div className='col-lg-4 col-form-label fw-bold fs-6 d-flex align-items-center'>
                        <Form.Check
                          type="switch"
                          id="default2FA"
                          // className='col-lg-4 col-form-label fw-bold fs-6'
                          checked={formik.values.FORMAT_CHAT_RESPONSE == 1}
                          onChange={(e) => {
                            formik.setFieldValue(env.meta_key, e.target.checked ? 1 : 0);
                          }}
                        />
                      </div>
                      </>
                    ) : (env.meta_key === 'IMAGE_PROMPT') ? (
                      <>
                        <textarea
                          className='form-control form-control-lg form-control-solid'
                          placeholder={transformString(env.meta_key)}
                          {...formik.getFieldProps(env.meta_key)}
                          style={{ height: '200px' }}
                          title="Changes may impact the Summary generated for documents"
                        ></textarea>
                      </>
                    ) : (env.meta_key === 'AUDIO_PROMPT') ? (
                      <>
                        <textarea
                          className='form-control form-control-lg form-control-solid'
                          placeholder={transformString(env.meta_key)}
                          {...formik.getFieldProps(env.meta_key)}
                          style={{ height: '200px' }}
                          title="Changes may impact the Summary generated for documents"
                        ></textarea>
                      </>
                    ) : (env.meta_key === 'VIDEO_PROMPT') ? (
                      <>
                        <textarea
                          className='form-control form-control-lg form-control-solid'
                          placeholder={transformString(env.meta_key)}
                          {...formik.getFieldProps(env.meta_key)}
                          style={{ height: '200px' }}
                          title="Changes may impact the Summary generated for documents"
                        ></textarea>
                      </>
                    ) : (env.meta_key === 'PDF_PROMPT') ? (
                      <>
                        <textarea
                          className='form-control form-control-lg form-control-solid'
                          placeholder={transformString(env.meta_key)}
                          {...formik.getFieldProps(env.meta_key)}
                          style={{ height: '200px' }}
                          title="Changes may impact the Summary generated for documents"
                        ></textarea>
                      </>
                    ) : (env.meta_key === 'XLSX_PROMPT') ? (
                      <>
                        <textarea
                          className='form-control form-control-lg form-control-solid'
                          placeholder={transformString(env.meta_key)}
                          {...formik.getFieldProps(env.meta_key)}
                          style={{ height: '200px' }}
                          title="Changes may impact the Summary generated for documents"
                        ></textarea>
                      </>
                    ) : (env.meta_key === 'DOC_PROMPT') ? (
                      <>
                        <textarea
                          className='form-control form-control-lg form-control-solid'
                          placeholder={transformString(env.meta_key)}
                          {...formik.getFieldProps(env.meta_key)}
                          style={{ height: '200px' }}
                          title="Changes may impact the Summary generated for documents"
                        ></textarea>
                      </>
                    ) : (env.meta_key === 'PPT_PROMPT') ? (
                      <>
                        <textarea
                          className='form-control form-control-lg form-control-solid'
                          placeholder={transformString(env.meta_key)}
                          {...formik.getFieldProps(env.meta_key)}
                          style={{ height: '200px' }}
                          title="Changes may impact the Summary generated for documents"
                        ></textarea>
                      </>
                    ) : (env.meta_key === 'FORMAT_SUFFIX' && formik.values.FORMAT_CHAT_RESPONSE == 1) ? (
                      <textarea
                        className='form-control form-control-lg form-control-solid'
                        placeholder={transformString(env.meta_key)}
                        {...formik.getFieldProps(env.meta_key)}
                        style={{ height: '100px' }}
                      ></textarea>
                    ) : (
                      <>
                      {
                        env.meta_key !== 'FORMAT_SUFFIX' &&
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder={transformString(env.meta_key)}
                          {...formik.getFieldProps(env.meta_key)}
                        />
                      }
                      </>
                    )}
                    {formik.touched[env.meta_key] && formik.errors[env.meta_key] && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'><>{formik.errors[env.meta_key]}</></div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
