import {FC} from 'react'
import { AudioRecorder } from './AudioRecorder'

const AudioSummarizer: FC = () => {
  return (
    <>
      <div className=''>
        <AudioRecorder />
      </div>
    </>
  )
}

export {AudioSummarizer}
