import { useEffect, useState } from 'react'
import { Dialog } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogTitle } from '@mui/material';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap'
import { superAdminOrgUpdate } from '../../api';

const profileDetailsSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .min(14, 'Minimum 10 numbers')
      .max(14, 'Maximum 10 numbers')
      .required('Company phone number is required'),
    companyName: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Company name is required'),
    orgType: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Organization type is required'),
    mailingAddStreetName: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Street name/number is required'),
    mailingAddCityName: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('City is required'),
    mailingAddStateName: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('State is required'),
    mailingAddZip: Yup.string()
      .min(5, 'Minimum 5 numbers')
      .max(5, 'Maximum 5 numbers')
      .required('Zip code is required'),
    billingAddStreetName: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Street name/number is required'),
    billingAddCityName: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('City is required'),
    billingAddStateName: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('State is required'),
    billingAddZip: Yup.string()
      .min(5, 'Minimum 5 numbers')
      .max(5, 'Maximum 5 numbers')
      .required('Zip code is required'),
})

interface AdminOrgDetailModel {
    companyId: string,
    phoneNumber: string,
    avatarName: string,
    companyName: string,
    orgType: string,
    mailingAddStreetName: string,
    mailingAddCityName: string,
    mailingAddStateName: string,
    mailingAddZip: string,
    billingAddStreetName: string,
    billingAddCityName: string,
    billingAddStateName: string,
    billingAddZip: string,
    isMailAndBillAddressSame: string,
    companytwoFactorAuth: string
}

export const EditSuperOrg = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [image, setImage] = useState<any>("")
    const [compPhoneNumb, setCompPhoneNumb] = useState<any>(props.orgDetail.phoneNumber)
    const isOtherOrgType = (type: string | undefined) => {
        if (!type) return false
        if (type == 'Company' || type == 'Non Profit') return false
        return true
    }
    
    const [isOtherSelected, setIsOtherSelected] = useState<boolean>(isOtherOrgType(props.orgDetail.orgType))
    const [orgType, setOrgType] = useState<string | undefined>(props.orgDetail.orgType)
    const [checkboxTick, setCheckboxTick] = useState<any>(props.orgDetail.isMailAndBillAddressSame)
    const [company2FA, setCompany2FA] = useState<boolean>(props.orgDetail.companytwoFactorAuth)

    const initialValues: AdminOrgDetailModel = {
        companyId: props.orgDetail.companyId,
        phoneNumber: props.orgDetail.phoneNumber,
        avatarName: props.orgDetail.avatarName,
        companyName: props.orgDetail.companyName,
        orgType: props.orgDetail.orgType,
        mailingAddStreetName: props.orgDetail.mailingAddStreetName,
        mailingAddCityName: props.orgDetail.mailingAddCityName,
        mailingAddStateName: props.orgDetail.mailingAddStateName,
        mailingAddZip: props.orgDetail.mailingAddZip,
        billingAddStreetName: props.orgDetail.billingAddStreetName,
        billingAddCityName: props.orgDetail.billingAddCityName,
        billingAddStateName: props.orgDetail.billingAddStateName,
        billingAddZip: props.orgDetail.billingAddZip,
        isMailAndBillAddressSame: props.orgDetail.isMailAndBillAddressSame,
        companytwoFactorAuth: props.orgDetailcompanytwoFactorAuth
    }

    const handleImageChange = (e: any) => {
        setImage(e.target.files[0])
    };

    const formik = useFormik({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append('companyId', values.companyId)
                formData.append('companyName', values.companyName)
                formData.append('phoneNumber', values.phoneNumber)
                formData.append('orgType', values.orgType)
                formData.append('mailingAddStreetName', values.mailingAddStreetName)
                formData.append('mailingAddCityName', values.mailingAddCityName)
                formData.append('mailingAddStateName', values.mailingAddStateName)
                formData.append('mailingAddZip', values.mailingAddZip)
                formData.append('billingAddStreetName', values.billingAddStreetName)
                formData.append('billingAddCityName', values.billingAddCityName)
                formData.append('billingAddStateName', values.billingAddStateName)
                formData.append('billingAddZip', values.billingAddZip)
                formData.append('isMailAndBillAddressSame', checkboxTick)
                formData.append('companytwoFactorAuth', company2FA ? "1" : "0")
                formData.append('image', image)

                superAdminOrgUpdate(formData)
                    .then((response) => {
                        if (response.data.success) {
                            window.location.reload()
                            setLoading(false)
                        } else {
                            setLoading(false)
                        }
                    })
                    .then(() => {
                        props.setShowOrgUpdateDialog(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        props.setShowOrgUpdateDialog(false)
                        setLoading(false)
                    })
            }, 1000)
        }
    })

    useEffect(() => {
        formik.setFieldValue('phoneNumber', compPhoneNumb)
    }, [compPhoneNumb])


    const handlePhoneNumberChange = (e: any, numbertype: 'Company' | 'Mobile') => {
        if (numbertype == 'Company') {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            setCompPhoneNumb(formattedPhoneNumber)
        } else {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        }
    }

    const formatPhoneNumber = (value: string) => {
        if (!value) return value;

        const phoneNumber = value.replace(/[^\d]/g, '');

        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    const updateOrgType = (_type: string) => {
        if (_type == 'Other') {
          setOrgType(_type)
          formik.setFieldValue('orgType', '')
          setIsOtherSelected(true)
        } else {
          setIsOtherSelected(false)
          formik.setFieldValue('orgType', _type)
          setOrgType(_type)
        }
    }

    const autoFillBillingAddress = (event: any) => {
        if (event.target.checked) {
          const streetName = formik.getFieldMeta('billingAddStreetName')
          const city = formik.getFieldMeta('billingAddCityName')
          const state = formik.getFieldMeta('billingAddStateName')
          const zipcode = formik.getFieldMeta('billingAddZip')
    
          if (streetName.value == '' || city.value == '' || state.value == '' || zipcode.value == '') {
            return
          }
    
          formik.setFieldValue('mailingAddStreetName', streetName.value)
          formik.setFieldValue('mailingAddCityName', city.value)
          formik.setFieldValue('mailingAddStateName', state.value)
          formik.setFieldValue('mailingAddZip', zipcode.value)
          setCheckboxTick(true)
        } else {
          setCheckboxTick(false)
          formik.setFieldValue('mailingAddStreetName', '')
          formik.setFieldValue('mailingAddCityName', '')
          formik.setFieldValue('mailingAddStateName', '')
          formik.setFieldValue('mailingAddZip', '')
        }
    }
    
    const handleStreetNameChange = (event: any, addressType: 'mailing' | 'billing') => {
        if (addressType == 'billing') {
          if (checkboxTick) {
            formik.setFieldValue('billingAddStreetName', event.target.value)
            formik.setFieldValue('mailingAddStreetName', event.target.value)
          } else {
            formik.setFieldValue('billingAddStreetName', event.target.value)
          }
        } else if (addressType == 'mailing') {
          formik.setFieldValue('mailingAddStreetName', event.target.value)
        }
    }
    
    const handleCityNameChange = (event: any, addressType: 'mailing' | 'billing') => {
        if (addressType == 'billing') {
          if (checkboxTick) {
            formik.setFieldValue('billingAddCityName', event.target.value)
            formik.setFieldValue('mailingAddCityName', event.target.value)
          } else {
            formik.setFieldValue('billingAddCityName', event.target.value)
          }
        } else if (addressType == 'mailing') {
          formik.setFieldValue('mailingAddCityName', event.target.value)
        }
    }
    
    const handleStateNameChange = (event: any, addressType: 'mailing' | 'billing') => {
        if (addressType == 'billing') {
          if (checkboxTick) {
            formik.setFieldValue('mailingAddStateName', event.target.value)
            formik.setFieldValue('billingAddStateName', event.target.value)
          } else {
            formik.setFieldValue('billingAddStateName', event.target.value)
          }
        } else if (addressType == 'mailing') {
          formik.setFieldValue('mailingAddStateName', event.target.value)
        }
    }
    
    const handleZipCodeChange = (event: any, addressType: 'mailing' | 'billing') => {
        if (addressType == 'billing') {
          if (checkboxTick) {
            formik.setFieldValue('mailingAddZip', event.target.value)
            formik.setFieldValue('billingAddZip', event.target.value)
          } else {
            formik.setFieldValue('billingAddZip', event.target.value)
          }
        } else if (addressType == 'mailing') {
          formik.setFieldValue('mailingAddZip', event.target.value)
        }
    }

    useEffect(() => {
        if (props.orgDetail.billingAddStreetName) {
            formik.setFieldValue('billingAddStreetName', props.orgDetail.billingAddStreetName)
            formik.setFieldValue('billingAddCityName', props.orgDetail.billingAddCityName)
            formik.setFieldValue('billingAddStateName', props.orgDetail.billingAddStateName)
            formik.setFieldValue('billingAddZip', props.orgDetail.billingAddZip)
        }
        if (props.orgDetail.mailingAddStreetName) {
            formik.setFieldValue('mailingAddStreetName', props.orgDetail.mailingAddStreetName)
            formik.setFieldValue('mailingAddCityName', props.orgDetail.mailingAddCityName)
            formik.setFieldValue('mailingAddStateName', props.orgDetail.mailingAddStateName)
            formik.setFieldValue('mailingAddZip', props.orgDetail.mailingAddZip)
        }
        
    }, [props.orgDetail])

    return (
        <>
            <Dialog
                open={props.showOrgUpdateDialog}
                onClose={() => props.setShowOrgUpdateDialog(false)}
                aria-labelledby="form-dialog-title"
                PaperProps={{ className: 'bg-light text-dark' }}
            >
                <DialogTitle className='px-5 text-center fw-bolder text-muted' id="form-dialog-title">
                    <div className="modal-header" id="kt_modal_update_user_header">
                        <h2 className="fw-bolder">
                            Update Organisation
                        </h2>
                        <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={() => props.setShowOrgUpdateDialog(false)}>
                            <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip" title="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect fill="#A9A9A9" x="0" y="7" width="16" height="2" rx="1" />
                                        <rect fill="#A9A9A9" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <form style={{ width: '380px' }} className="form" onSubmit={formik.handleSubmit} id="kt_modal_update_user_form">
                        <div
                            className="modal-body px-5"
                        >
                            <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_user_header" data-kt-scroll-wrappers="#kt_modal_update_user_scroll" data-kt-scroll-offset="300px">
                                <div id="kt_modal_update_user_user_info" className="show">
                                    <div className="mb-7">
                                        <label className="fs-6 fw-bold mb-2 me-5">
                                            <span>
                                                Organization Logo
                                            </span>
                                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Allowed file types: png, jpg, jpeg."></i>
                                        </label>
                                        <div className="mt-5">
                                            <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`, marginLeft: '5px' }}>
                                                <div
                                                    className="image-input-wrapper w-125px h-125px"
                                                    style={{ backgroundImage: `url(${image === "" ? `${props.orgDetail?.avatarName}` : URL.createObjectURL(image)})` }}
                                                ></div>
                                                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                                    <i className="bi bi-pencil-fill fs-7"></i>
                                                    <input onChange={(e) => handleImageChange(e)} type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                                    <input type="hidden" name="avatar_remove" />
                                                </label>
                                                <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                                    <i className="bi bi-x fs-2"></i>
                                                </span>
                                                <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                                    <i className="bi bi-x fs-2"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fv-row mb-7">
                                        <label className="required fs-6 fw-bold mb-2">
                                            Company / Organization Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder="Organisation Name"
                                            {...formik.getFieldProps('companyName')}
                                        />
                                        {formik.touched.companyName && formik.errors.companyName && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.companyName}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="fv-row mb-7">
                                        <label className="required fs-6 fw-bold mb-2">
                                            Company Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder="Company Number"
                                            {...formik.getFieldProps('phoneNumber')}
                                            onChange={(e) => handlePhoneNumberChange(e, 'Company')}
                                        />
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                                            </div>
                                        )}
                                    </div>

                                    {/* company type  */}
                                    <div className='fv-row mb-7 org-type'>
                                        <label className='form-label fw-bolder text-dark fs-6 required text-nowrap'><FormattedMessage id='COMPANY.PROFILE.TYPE' /></label>
                                        <div>
                                            {/*begin:Option */}
                                            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                                            <span className='d-flex align-items-center me-2'>
                                                <span className='d-flex flex-column'>
                                                <span className='fw-bold text-muted fs-6'><FormattedMessage id='AUTH.REGISTER.ORG_TYPE.TYPE1' /></span>
                                                </span>
                                            </span>

                                            <span className='form-check form-check-custom form-check-solid'>
                                                <input
                                                className='form-check-input'
                                                type='radio'
                                                name='orgType'
                                                value='Company'
                                                checked={orgType === 'Company' && !isOtherSelected}
                                                onChange={() => updateOrgType('Company')}
                                                />
                                            </span>
                                            </label>
                                            {/*end::Option */}

                                            {/*begin:Option */}
                                            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                                            <span className='d-flex align-items-center me-2'>
                                                <span className='d-flex flex-column'>
                                                <span className='fw-bold text-muted fs-6'><FormattedMessage id='AUTH.REGISTER.ORG_TYPE.TYPE2' /></span>
                                                </span>
                                            </span>

                                            <span className='form-check form-check-custom form-check-solid'>
                                                <input
                                                className='form-check-input'
                                                type='radio'
                                                name='orgType'
                                                value='Non Profit'
                                                checked={orgType === 'Non Profit' && !isOtherSelected}
                                                onChange={() => updateOrgType('Non Profit')}
                                                />
                                            </span>
                                            </label>
                                            {/*end::Option */}

                                            {/*begin:Option */}
                                            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                                            <span className='d-flex align-items-center me-2'>
                                                <span className='d-flex flex-column'>
                                                <span className='fw-bold text-muted fs-6'><FormattedMessage id='AUTH.REGISTER.ORG_TYPE.TYPE3' /></span>
                                                </span>
                                            </span>

                                            <span className='form-check form-check-custom form-check-solid'>
                                                <input
                                                className='form-check-input'
                                                type='radio'
                                                name='appType'
                                                value='Other'
                                                checked={isOtherSelected}
                                                onChange={() => updateOrgType('Other')}
                                                />
                                            </span>
                                            </label>
                                            {/*end::Option */}

                                            {isOtherSelected &&
                                            <>
                                                <input
                                                type='text'
                                                placeholder='Organization type'
                                                autoComplete='off'
                                                {...formik.getFieldProps('orgType')}
                                                className="form-control form-control-lg form-control-solid"
                                                />

                                                <>
                                                {formik.touched.orgType && formik.errors.orgType && (
                                                    <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'><>{formik.errors.orgType}</></span>
                                                    </div>
                                                    </div>
                                                )}
                                                </>
                                            </>
                                            }

                                        </div>
                                    </div>

                                    {/* billing address  */}
                                    <label
                                        className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                                        htmlFor='kt_create_account_form_account_type_personal'
                                        >
                                        <span className='w-100 fw-bold text-start'>
                                            <span className='text-dark fw-bolder d-block fs-4 mb-2'><FormattedMessage id='AUTH.REGISTER.BILLING_ADDRESS' /></span>
                                            <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'><FormattedMessage id='COMMUNITY.ADDRESS.STREET' /></span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Street Name'
                                                {...formik.getFieldProps('billingAddStreetName')}
                                                onChange={(e) => handleStreetNameChange(e, 'billing')}
                                                />

                                                {formik.touched.billingAddStreetName && formik.errors.billingAddStreetName && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'><>{formik.errors.billingAddStreetName}</></div>
                                                </div>
                                                )}
                                            </div>
                                            </div>

                                            <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'><FormattedMessage id='COMMUNITY.ADDRESS.CITY' /></span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='City Name'
                                                {...formik.getFieldProps('billingAddCityName')}
                                                onChange={(e) => handleCityNameChange(e, 'billing')}
                                                />

                                                {formik.touched.billingAddCityName && formik.errors.billingAddCityName && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'><>{formik.errors.billingAddCityName}</></div>
                                                </div>
                                                )}
                                            </div>
                                            </div>

                                            <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'><FormattedMessage id='COMMUNITY.ADDRESS.STATE' /></span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>

                                                <select
                                                value={formik.getFieldMeta('billingAddStateName').value}
                                                className='form-select form-select-lg form-select-solid'
                                                name='states'
                                                onChange={(e) => handleStateNameChange(e, 'billing')}
                                                >
                                                <option value="">Select a State</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District Of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                                </select>
                                            </div>
                                            </div>

                                            <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'><FormattedMessage id='COMMUNITY.ADDRESS.ZIP' /></span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Zipcode'
                                                {...formik.getFieldProps('billingAddZip')}
                                                onChange={(e) => handleZipCodeChange(e, 'billing')}
                                                />

                                                {formik.touched.billingAddZip && formik.errors.billingAddZip && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'><>{formik.errors.billingAddZip}</></div>
                                                </div>
                                                )}
                                            </div>
                                            </div>
                                        </span>
                                    </label>

                                    {/* mailing address  */}
                                    <label
                                        className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                                        htmlFor='kt_create_account_form_account_type_personal'
                                        >
                                        <span className='w-100 fw-bold text-start'>
                                            <span className='text-dark fw-bolder d-block fs-4 mb-2'><FormattedMessage id='AUTH.REGISTER.MAILING_ADDRESS' /></span>

                                            <div className='fv-row'>
                                            <input
                                                className="form-check-input me-5"
                                                onChange={(e) => autoFillBillingAddress(e)}
                                                type="checkbox"
                                                checked={checkboxTick}
                                            />
                                            <label className='form-label fw-bolder text-dark fs-6'><FormattedMessage id='AUTH.REGISTER.ADDRESS.SAME' /></label>
                                            </div>

                                            {!checkboxTick &&
                                            <>
                                                <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span className='required'><FormattedMessage id='COMMUNITY.ADDRESS.STREET' /></span>
                                                </label>

                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Street Name'
                                                    {...formik.getFieldProps('mailingAddStreetName')}
                                                    onChange={(e) => handleStreetNameChange(e, 'mailing')}
                                                    />

                                                    {formik.touched.mailingAddStreetName && formik.errors.mailingAddStreetName && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'><>{formik.errors.mailingAddStreetName}</></div>
                                                    </div>
                                                    )}
                                                </div>
                                                </div>

                                                <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span className='required'><FormattedMessage id='COMMUNITY.ADDRESS.CITY' /></span>
                                                </label>

                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='City Name'
                                                    {...formik.getFieldProps('mailingAddCityName')}
                                                    onChange={(e) => handleCityNameChange(e, 'mailing')}
                                                    />

                                                    {formik.touched.mailingAddCityName && formik.errors.mailingAddCityName && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'><>{formik.errors.mailingAddCityName}</></div>
                                                    </div>
                                                    )}
                                                </div>
                                                </div>

                                                <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span className='required'><FormattedMessage id='COMMUNITY.ADDRESS.STATE' /></span>
                                                </label>

                                                <div className='col-lg-8 fv-row'>

                                                    <select
                                                    value={formik.getFieldMeta('mailingAddStateName').value}
                                                    className='form-select form-select-lg form-select-solid'
                                                    name='states'
                                                    onChange={(e) => handleStateNameChange(e, 'mailing')}
                                                    >
                                                    <option value="">Select a State</option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AK">Alaska</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="DC">District Of Columbia</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>
                                                    <option value="HI">Hawaii</option>
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NY">New York</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                                </div>

                                                <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span className='required'><FormattedMessage id='COMMUNITY.ADDRESS.ZIP' /></span>
                                                </label>

                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Zipcode'
                                                    {...formik.getFieldProps('mailingAddZip')}
                                                    onChange={(e) => handleZipCodeChange(e, 'mailing')}
                                                    />

                                                    {formik.touched.mailingAddZip && formik.errors.mailingAddZip && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'><>{formik.errors.mailingAddZip}</></div>
                                                    </div>
                                                    )}
                                                </div>
                                                </div>
                                            </>
                                            }
                                        </span>
                                    </label>

                                    <div className="fv-row mb-7">
                                        <label className="required fs-6 fw-bold mb-2">
                                            Two Factor Authentication
                                        </label>
                                        <Form.Check
                                            type="switch"
                                            id="default2FA"
                                            className='col-lg-4 col-form-label fw-bold fs-6'
                                            checked={company2FA === true}
                                            onChange={(e) => {
                                                setCompany2FA(e.target.checked)
                                            formik.setFieldValue("companytwoFactorAuth", e.target.checked ? 1 : 0);
                                            }}
                                        />
                                        {formik.touched.companytwoFactorAuth && formik.errors.companytwoFactorAuth && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.companytwoFactorAuth}</div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer flex-center">
                            <button
                                type="reset"
                                className="btn btn-light me-3"
                                onClick={() => props.setShowOrgUpdateDialog(false)}
                            >
                                Discard
                            </button>
                            <button type="submit" className="btn btn-primary">
                                <span className="indicator-label">Submit</span>
                                {loading &&
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                }
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}